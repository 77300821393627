import { BaseSchema, ModelType, type ToManyRelationship, type ToOneRelationship } from '~~/app/base/schemas/BaseSchema';

type WorksiteRelationships = {
    users: ToManyRelationship<ModelType.USERS>;
    managers: ToManyRelationship<ModelType.USERS>;
    modelEvents: ToManyRelationship<ModelType.MODEL_EVENTS>;
};

type WorksiteAttributes = {
    title: string;
    color: string;
    notes?: string | null;
    address?: Address | null;
    startsAt: string;
    endsAt?: string | null;
    archivedAt?: string | null;
};

type WorksiteMeta = {
    profitability: WorksiteMetaProfitability;
};
export type WorksiteMetaProfitability = {
    budget: number;
    revenues: number;
    revenuesPaid: number;
    costs: number;
    margin: number;
    expectedLiquidities: number;
    currentLiquidities: number;
};

export default class WorksiteSchema extends BaseSchema<WorksiteAttributes, WorksiteMeta, WorksiteRelationships> {
    protected type: ModelType = ModelType.WORKSITES;
}
